import React, { FC } from 'react';
import { graphql, navigate } from 'gatsby';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';
import Image from 'common/Image';
import SpeechBubble from 'components/SpeechBubble';
import { toBoolean } from 'utils/stringUtils/stringUtils';

import './SignUpCardsItem.scss';

const SignUpCardsItem: FC<PageContent.SignUpCardsItemType> = ({
  image,
  bubbleText,
  customText,
  title,
  description,
  button,
  reverse,
}) => {
  const { ariaLabel, backgroundColor, size, label, link } = button || {};
  const { url } = link || {};

  return (
    <div className="sign-up-cards-item">
      <div className="sign-up-cards-item__top-content">
        <Image className="sign-up-cards-item__image" imageData={image} alt={image.altText} />
        {bubbleText ? <SpeechBubble {...bubbleText} /> : null}
        {customText ? (
          <DangerouslySetInnerHtml className="sign-up-cards-item__custom-text" html={customText} />
        ) : null}
      </div>
      <div
        className={classNames('sign-up-cards-item__bottom-content', {
          'sign-up-cards-item__bottom-content__reverse': toBoolean(reverse),
        })}
      >
        <div className="sign-up-cards-item__text">
          {title ? <h2 className="sign-up-cards-item__title">{title}</h2> : null}
          <DangerouslySetInnerHtml className="sign-up-cards-item__description" html={description} />
        </div>
        <div className="sign-up-cards-item__button">
          {button ? (
            <Button
              className="btn--sign-up"
              ariaLabel={ariaLabel}
              backgroundColor={backgroundColor}
              size={size}
              type="button"
              onClick={() => navigate(url!)}
              label={label}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const SignUpCardsItemType = graphql`
  fragment SignUpCardsItemType on SignUpCardsItemType {
    image {
      ...UmbracoImage
    }
    customText
    button {
      ariaLabel
      backgroundColor
      label
      link {
        url
      }
      size
    }
    bubbleText {
      text
      image {
        ...UmbracoImage
      }
      color {
        value
      }
      isReversed
      isCardMode
    }
    title
    description
    button {
      ariaLabel
      backgroundColor
      label
      link {
        url
      }
      size
    }
    reverse
  }
`;

export default SignUpCardsItem;
