import React from 'react';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';

import './Infocard.scss';

const Infocard = ({
  infoCardTitle,
  infoCardDescription,
  infoCardType,
  infoCardList,
}: InfoPageTypes.InfoCardType) => (
  <div
    className={classNames('info-card', {
      [`info-card--${infoCardType}`]: infoCardType,
    })}
    data-testid="info-card"
  >
    <h2 className="info-card__title">{infoCardTitle}</h2>
    <h3 className="info-card__description">{infoCardDescription}</h3>
    <ul className="info-card__list">
      {infoCardList.map(({ infoCardListItem }: InfoPageTypes.InfoCardListItemType) => (
        <li className="info-card__list-item" key={infoCardListItem}>
          <DangerouslySetInnerHtml
            html={infoCardListItem}
            className="info-card__list-item-content"
          />
        </li>
      ))}
    </ul>
  </div>
);
export default Infocard;
