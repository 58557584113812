import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Carousel from 'common/Carousel';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import InfoCardsContainer from 'containers/InfoCardsContainer';
import AdditionalInfo from 'components/AdditionalInfo';
import InfoBanner from 'components/InfoBanner';
import SignUpCards from 'components/SignUpCards';

import './InfoPage.scss';

const InfoPage: FC<InfoPageTypes.InfoPageProps> = ({
  data: {
    infoPage: {
      urls,
      seo,
      infoCards,
      infoBanner,
      additionalInfo,
      mainCarousel,
      signUpCards,
      secondAdditionalInfo,
      thirdAdditionalInfo,
    },
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <Layout className="info-page" headerTransparent>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      {mainCarousel ? (
        <Carousel
          {...{
            carouselImages: mainCarousel,
            className: 'main-carousel',
          }}
        />
      ) : null}
      {additionalInfo ? <AdditionalInfo {...{ ...additionalInfo }} /> : null}
      {infoCards ? <InfoCardsContainer {...{ infoCards }} /> : null}
      {additionalInfo ? <AdditionalInfo {...{ ...secondAdditionalInfo }} /> : null}
      {signUpCards ? <SignUpCards {...{ cards: signUpCards }} /> : null}
      {additionalInfo ? <AdditionalInfo {...{ ...thirdAdditionalInfo }} /> : null}
      <InfoBanner {...{ infoBanner }} />
    </Layout>
  );
};

export const query = graphql`
  query InfoQuery($lang: String, $id: String) {
    infoPage(lang: { eq: $lang }, id: { eq: $id }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      infoCards {
        infoCardDescription
        infoCardType
        infoCardTitle
        infoCardList {
          infoCardListItem
        }
      }
      infoBanner {
        ...InfoBannerTypeFragment
      }
      mainCarousel {
        carouselItems {
          ...CarouselItemsType
        }
      }
      additionalInfo {
        ...AdditionalInfoType
      }
      signUpCards {
        sectionTitle
        cardsList {
          ...SignUpCardsItemType
        }
      }
      secondAdditionalInfo {
        ...AdditionalInfoType
      }
      thirdAdditionalInfo {
        ...AdditionalInfoType
      }
    }
  }
`;

export default InfoPage;
