import React, { FC } from 'react';

import { SignUpCardsProps } from './models';
import SignUpCardsItem from './SignUpCardsItem';

import './SignUpCards.scss';

const SignUpCards: FC<SignUpCardsProps> = ({ cards: { sectionTitle, cardsList } }) => (
  <div className="sign-up-cards" data-testid="signUpCards">
    {sectionTitle ? <h2 className="sign-up-cards__section-title">{sectionTitle}</h2> : null}
    {cardsList.map((SignUpCardsItemProps: PageContent.SignUpCardsItemType) => (
      <SignUpCardsItem key={SignUpCardsItemProps.title} {...SignUpCardsItemProps} />
    ))}
  </div>
);

export default SignUpCards;
