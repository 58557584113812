import React from 'react';
import classNames from 'classnames';

import Infocard from 'components/InfoCard';

import './InfoCardsContainer.scss';

const InfoCardsContainer = ({ infoCards }: InfoPageTypes.InfoCardsContainerProps) => (
  <section
    className={classNames('container info-cards-container', {
      'info-cards-container--two-columns': infoCards.length === 2,
    })}
    data-testid="info-cards-container"
  >
    {infoCards.map(({ infoCardTitle, infoCardDescription, infoCardList, infoCardType }) => (
      <Infocard
        key={infoCardTitle}
        {...{ infoCardTitle, infoCardDescription, infoCardType, infoCardList }}
      />
    ))}
  </section>
);

export default InfoCardsContainer;
